import { useFetchMovies, useFetchOrderHistory } from "@multicines/services";
import { useCataloguesStore } from "@multicines/stores";
import { getActiveTicketOrders } from "@multicines/utils";
import { TabsMenu as TabsMenuUI } from "artisn-ui-react";
import { useRouter } from "next/router";
import React, { useState, useEffect, useMemo } from "react";

import Styles from "./TabsMenu.styles";
import { TabsMenuProps as Props } from "./TabsMenu.types";
import ActiveTicket from "../ActiveTicket/ActiveTicket";
import MoviesTabsMenuItem from "../navigation/MoviesTabsMenuItem/MoviesTabsMenuItem";
import MulticlubTabsMenuItem from "../navigation/MulticlubTabsMenuItem/MulticlubTabsMenuItem";
import SnacksTabsMenuItem from "../navigation/SnacksTabsMenuItem/SnacksTabsMenuItem";
import SpotlightTabsMenuItem from "../navigation/SpotlightTabsMenuItem/SpotlightTabsMenuItem";
import UserTabsMenuItem from "../navigation/UserTabsMenuItem/UserTabsMenuItem";
import CONSTANTS from "config/constants";
import useTalkShop from "hooks/useTalkShop";
import { useAuthStore } from "stores/auth/auth.store";
import { useStoresStore } from "stores/stores/stores.store";

const { tablet } = CONSTANTS.BREAKPOINTS;

const TabsMenu: React.FC<Props> = props => {
  const { children } = props;
  const { pathname } = useRouter();
  const { isTalkShop } = useTalkShop();
  const [active, setActive] = useState(-1);
  const selectedStore = useStoresStore(store => store.selectedStore);
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const { data: movies } = useFetchMovies({ isAnonymous, uid }, selectedStore);
  const selectedCatalogueId = useCataloguesStore(
    state => state.selectedCatalogue.catalogueId
  );
  const { data: orders } = useFetchOrderHistory(
    { isAnonymous, uid },
    selectedCatalogueId,
    "DONE"
  );

  const activeOrder = useMemo(() => {
    const [firstActiveOrder] = getActiveTicketOrders(orders) ?? [];
    return firstActiveOrder;
  }, [orders]);
  const show = !!activeOrder && !!movies;

  useEffect(() => {
    if (pathname === "/") {
      setActive(0);
    } else if (pathname.startsWith("/billboard")) {
      setActive(1);
    } else if (pathname.startsWith("/categories")) {
      setActive(2);
    } else if (pathname.startsWith("/profile/membership")) {
      setActive(3);
    } else if (
      pathname.startsWith("/profile") ||
      pathname.startsWith("/profile/tickets")
    ) {
      setActive(4);
    }
  }, [pathname]);

  const tabs = [
    <MoviesTabsMenuItem key={1} active={active === 1} />,
    <SnacksTabsMenuItem key={2} active={active === 2} />,
    <MulticlubTabsMenuItem key={3} active={active === 3} />,
    <SpotlightTabsMenuItem key={0} active={active === 0} />,
    show ? (
      <ActiveTicket className="TabsMenu__active" {...props} />
    ) : (
      <UserTabsMenuItem key={4} active={active === 4} />
    )
  ];

  if (isTalkShop) {
    return <Styles className="TabsMenu">{children}</Styles>;
  }

  return (
    <Styles className="TabsMenu">
      <TabsMenuUI tabs={tabs} maxMobileWidth={tablet + 1}>
        {children}
      </TabsMenuUI>
    </Styles>
  );
};

TabsMenu.defaultProps = {};

export default TabsMenu;
